.spinner {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  position: fixed;
  top: 0;
  left: 8%;
  height: 100%;
  z-index: 20;
}

.spinner span {
  font-size: 2rem;
  color: #23a455;
  animation: spin 1s linear infinite;
}
@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@media (max-width: 650px) {
  .spinner {
    left: 0;
  }
}
