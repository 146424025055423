@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600;700&display=swap");

.img {
  @apply w-full object-cover;
}
.p {
  @apply font-normal text-sm capitalize text-center;
}
.h1 {
  @apply text-2xl lg:text-3xl font-semibold text-[#57585A]  text-center capitalize;
}
.input-container {
  @apply flex relative flex-col space-y-1;
}
.input {
  @apply focus:border-green-500 transition-all duration-300 py-2 flex-1 px-4 border-2 rounded-xl outline-none;
}
.label {
  @apply text-xs lg:text-base font-medium capitalize text-start text-[#57585A];
}
.action {
  @apply flex space-x-6 items-center justify-between;
}
.button {
  @apply bg-[#23A455] flex-1 hover:opacity-40 transition-all duration-300  hover:shadow-xl capitalize rounded-lg text-white text-base font-semibold cursor-pointer py-2 lg:py-2 px-3 lg:px-6;
}
.btn {
  @apply bg-transparent text-[#23A455] font-bold text-sm lg:text-base capitalize;
}
.btn1 {
  @apply hover:opacity-80  transition-all duration-300 bg-transparent text-[#23A455] font-bold text-[0.6rem] lg:text-base capitalize;
}
.error-text {
  @apply text-red-600 font-semibold capitalize text-xs;
}
.active-button {
  @apply bg-[#23A455] flex-1 hover:opacity-80 transition-all duration-300  capitalize rounded-lg text-white text-xs lg:text-base font-semibold cursor-pointer py-2 px-4 lg:py-3 lg:px-6;
}
.inactive-button {
  @apply bg-transparent flex-1 hover:opacity-80 transition-all duration-300  capitalize rounded-lg text-[#9B9B9B] text-xs lg:text-base font-semibold cursor-pointer py-3 px-6;
}
.balance {
  @apply text-[1.5rem] lg:text-3xl font-bold capitalize text-[#00AC45];
}
button:disabled {
  @apply opacity-40;
}
.form {
  @apply flex w-full lg:w-[70%] p-4 mx-auto flex-col space-y-2;
}
.form1 {
  @apply flex w-full lg:w-[70%] p-4 mx-auto flex-col space-y-6;
}
.auth-bg {
  @apply h-[100vh]   overflow-hidden flex items-center justify-center;
}
.auth-large {
  @apply flex rounded-r-2xl h-full p-8 bg-white items-center flex-1 justify-center;
}
.auth-container {
  @apply flex h-full rounded-r-2xl lg:p-8 p-4 lg:px-12 bg-white items-center w-full lg:flex-1 justify-center;
}
.small-input {
  @apply w-[16%] h-full focus:border-green-500 lg:w-[64px] lg:h-[64px] p-2 rounded-lg text-center text-base lg:text-[40px] font-bold bg-[#fff] outline-none  border-2;
}
.menu {
  @apply flex items-center gap-4 capitalize font-medium space-x-4 text-[16px] pl-4 py-6 hover:opacity-80 transition-all duration-300;
}
.active {
  @apply border-l-4 gap-4 capitalize pl-4 rounded-sm py-6 hover:opacity-80 transition-all font-bold duration-300 border-green-600 flex items-center space-x-4 text-[16px];
}
.dashboard-icon {
  @apply text-xl text-black font-bold cursor-pointer;
}
.dash-t {
  @apply capitalize font-normal text-[0.88rem] text-[#57585A] text-start;
}
.order-text {
  @apply capitalize text-[0.5rem] lg:text-base font-bold  text-[#929EAE];
}
.order-item-text {
  @apply capitalize text-[0.5rem] lg:text-base    text-[#5D5F5F];
}
.product-text {
  @apply text-xs lg:text-[0.875rem] text-[#595F69] font-normal text-start capitalize;
}
.nav {
  @apply text-lg font-normal text-white capitalize hover:opacity-80 transition-all duration-300;
}
.nav-active {
  @apply text-lg font-bold text-white capitalize hover:opacity-80 transition-all duration-300;
}
.nav-custom {
  @apply text-lg font-normal text-[#120A00] capitalize hover:opacity-80 transition-all duration-300;
}
.nav-active-custom {
  @apply text-lg font-bold text-[#23A455] border-b-4 border-[#23A455] py-2 capitalize hover:opacity-80 transition-all duration-300;
}
.home-btn {
  @apply text-[#23A455] bg-white rounded-lg p-2 hover:opacity-80 hover:shadow-lg transition-all duration-300 font-bold capitalize;
}
body {
  font-family: "Quicksand", sans-serif;
}
.page-active {
  @apply bg-[#23A455] flex-1 hover:opacity-40 transition-all duration-300  hover:shadow-xl capitalize rounded-xl text-white text-base font-semibold cursor-pointer py-2 lg:py-1 px-3 lg:px-4;
}
.page-inactive {
  @apply bg-transparent border-2 border-[#23A455] flex-1 hover:opacity-40 transition-all duration-300  hover:shadow-xl capitalize rounded-xl text-[#23A455] text-base font-semibold cursor-pointer py-2 lg:py-1 px-3 lg:px-4;
}
::-webkit-scrollbar {
  width: 2px;
  height: 0px;
}
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  /* border-radius: 10px; */
  height: 3px;
  width: 4px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #ccc;
  border-radius: 10px;
}
::-webkit-scrollbar-track {
  margin: 20rem 0;
}

.checkbox input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}
.checkbox input:checked + .slider {
  background-color: #23a455;
}

.checkbox input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}
